import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

type UserFields = 'email' | 'password';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public newUser = false;
  public signup: boolean;

  public email = '';
  public password = '';

  public errors = {
    email_required: false,
    password_required: false,
  }

  constructor(private route: Router, public http: HttpClient) {
  }

  ngOnInit() {
  }

  signin() {
    this.http.post<any>('https://devcherish.site/admin/login', { email: this.email, password: this.password }).subscribe(res => {
      console.log(res);
      localStorage.setItem('token', res.token);
      this.route.navigateByUrl('/dash');
    }, err => {
      Swal.fire({
        type: 'error',
        title: 'Ошибка',
        text: 'Пользователь не найден!'
      })
    });
  }

}
