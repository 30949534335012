import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { RequestService } from "src/app/services/request.service";

// Menu
export interface Menu {
	headTitle1?: string;
	headTitle2?: string;
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: "root",
})
export class NavService implements OnDestroy {
	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Full screen
	public fullScreen: boolean = false;

	public needVerifyCounter = 0;

	constructor(private router: Router, public requestService: RequestService) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, "resize")
			.pipe(debounceTime(1000), takeUntil(this.unsubscriber))
			.subscribe((evt: any) => {
				this.setScreenWidth(evt.target.innerWidth);
				if (evt.target.innerWidth < 991) {
					this.collapseSidebar = true;
					this.megaMenu = false;
					this.levelMenu = false;
				}
				if (evt.target.innerWidth < 1199) {
					this.megaMenuColapse = true;
				}
			});
		if (window.innerWidth < 991) {
			// Detect Route change sidebar close
			this.router.events.subscribe((event) => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}

		this.updateBadges();
	}

	async updateBadges() {

		await this.requestService.get('user/needVerify').then(result => {
			if (result.length > 0) {
				this.MENUITEMS[3].badgeValue = String(result.length);
			} else {
				this.MENUITEMS[3].badgeValue = '';
			}
		})

		await this.requestService.get('referal/newOutcomes').then(result => {
			if (result.length > 0) {
				this.MENUITEMS[9].badgeValue = String(result.length);
			} else {
				this.MENUITEMS[9].badgeValue = '';
			}
		})

		setTimeout(() => {
			//this.updateBadges();
		}, 1000);
	}

	ngOnDestroy() {
		// this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	MENUITEMS: Menu[] = [
		{
			title: 'Dashboard', type: 'link', path: '/dash'
		}, {
			title: 'Administarors', type: 'link', path: '/admins'
		}, {
			title: 'Users', type: 'link', path: '/users'
		}, {
			title: 'Verification', type: 'link', path: '/verify', badgeType: "danger"
		}, {
			title: 'Reviews', type: 'link', path: '/reviews'
		}, {
			title: 'Properties', type: 'link', path: '/properties'
		}, {
			title: 'Restaurants', type: 'link', path: '/restaurants'
		}, {
			title: 'Settings', type: 'sub', children: [
				{
					title: 'Property', type: 'sub', children: [
						// { title: 'Типы здания', path: '/buildtypes', type: 'link' },
						{ title: 'Типы жилья', path: '/propertytypes', type: 'link' },
						{ title: 'Категории жилья', path: '/propertycategories', type: 'link' },
						{ title: 'Типы комнат', path: '/roomtypes', type: 'link' },
						{ title: 'Типы кроватей', path: '/bedtypes', type: 'link' },
						{ title: 'Категории удобств', path: '/comfortcategories', type: 'link' },
						// { title: 'Типы удобств', path: '/linkplaceholder', type: 'link' },
						{ title: 'Удобства', path: '/comfortitems', type: 'link' },
						{ title: 'Описания', path: '/description', type: 'link' },
						{ title: 'Домашние правила', path: '/rules', type: 'link' },
						{ title: 'Типы жалоб', path: '/reports', type: 'link' },
						{ title: 'Возможности оплат', path: '/paymenttypes', type: 'link' },
						{ title: 'Минимальное пребывание', path: '/minterm', type: 'link' },
						{ title: 'Расположение пребывания', path: '/locations', type: 'link' },
						{ title: 'Отзывы', path: '/reviewtypes', type: 'link' }
					]
				},
				{
					title: 'Staff', type: 'sub', children: [
						{ title: 'Перечень помещений', path: '/personalrooms', type: 'link' },
						{ title: 'Перечень задач', path: '/personaltasks', type: 'link' },
						{ title: 'Перечень персонала', path: '/personaltypes', type: 'link' }
					]
				},
				{
					title: 'Landlord', type: 'sub', children: [
						{ title: 'Премиум пакеты', path: '/landlordpremiums', type: 'link' },
						{ title: 'Условия и соглашения', path: '/landlordterm', type: 'link' },
						{ title: 'Управление арендой', path: '/landlordtarifs', type: 'link' }
					]
				},
				{
					title: 'Tenant', type: 'sub', children: [
						{ title: 'Условия и соглашения', path: '/tenantterm', type: 'link' },
						{ title: 'Запросы жилья', path: '/tenanttarifs', type: 'link' },
						{ title: 'Отзывы', path: '/tenantreviewtype', type: 'link' }
					]
				},
				{
					title: "Restaurant", type: 'sub', children: [
						{ title: 'Премиум пакеты', path: '/restauranttarifs', type: 'link' },
						{ title: 'Условия и соглашения', path: '/restaurantterm', type: 'link' },
						{ title: 'Типы ресторанов', path: '/restauranttypes', type: 'link' },
						{ title: 'Ингредиенты', path: '/restaurantingredients', type: 'link' },
						{ title: 'Reviews', path: '/restaurantreviews', type: 'link' }
					]
				},
				{
					title: 'Support', type: 'sub', children: [
						{ title: 'Типы обратной связи', path: '/feedbacktype', type: 'link' }
					]
				},
				{
					title: 'Requests', type: 'sub', children: [
						{ title: 'Запросы арендатора арендодателям', path: '/linkplaceholder', type: 'link' },
						{ title: 'Предложения арендодателей арендатору', path: '/linkplaceholder', type: 'link' },
						{ title: 'Принятие предложения арендатором', path: '/linkplaceholder', type: 'link' }
					]
				},
				{ title: 'Information', path: '/linkplaceholder', type: 'link' }
			]
		}, {
			title: 'Transactions', type: 'link', path: '/transactions'
		}, {
			title: 'Referral payouts', type: 'link', path: '/outcomes', badgeType: "danger"
		}, {
			title: 'Support', type: 'link', path: '/support'
		},
		//{
		// 	title: 'Reports', type: 'link', path: '/stat'
		//  },
		//, {
		// 	title: 'Website setting', type: 'link', path: '/configs'
		// },
		// {
		// 	title:'Жалобы', type:'sub', children:[
		// 		{ title: 'Отзывы', path: '/complaintreview', type: 'link' },
		// 		{ title: 'Объекты', path: '/complaintproperty', type: 'link' }
		// 	]
		// },
		{
			title: 'Backup', type: 'link', path: '/backup'
		}
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
