import { Routes } from "@angular/router";

export const content: Routes = [
	{
		path: "simple-page",
		loadChildren: () => import("../../components/simple-page/simple-page.module").then((m) => m.SimplePageModule),
	},
	{
		path: "single-page",
		loadChildren: () => import("../../components/single-page/single-page.module").then((m) => m.SinglePageModule),
	},
	{
		path: "dash",
		loadChildren: () => import("../../components/dash/dash.module").then((m) => m.DashPageModule),
	},
	{
		path: 'buildtypes',
		loadChildren: () => import('../../components/settings/property/buildtypes/buildtypes.module').then(m => m.BuildtypesModule)
	},
	{
		path: 'propertytypes',
		loadChildren: () => import('../../components/settings/property/propertytypes/propertytypes.module').then(m => m.PropertytypesModule)
	},
	{
		path: 'propertycategories',
		loadChildren: () => import('../../components/settings/property/propertycategories/propertycategories.module').then(m => m.PropertycategoriesModule)
	},
	{
		path: 'roomtypes',
		loadChildren: () => import('../../components/settings/property/roomtypes/roomtypes.module').then(m => m.RoomtypesModule)
	},
	{
		path: 'bedtypes',
		loadChildren: () => import('../../components/settings/property/bedtypes/bedtypes.module').then(m => m.BedtypesModule)
	},
	{
		path: 'comfortcategories',
		loadChildren: () => import('../../components/settings/property/comfortcategories/comfortcategories.module').then(m => m.ComfortcategoriesModule)
	},
	{
		path: 'comfortitems',
		loadChildren: () => import('../../components/settings/property/comfortitems/comfortitems.module').then(m => m.ComfortitemsModule)
	},
	{
		path: 'description',
		loadChildren: () => import('../../components/settings/property/description/description.module').then(m => m.DescriptionModule)
	},
	{
		path: 'rules',
		loadChildren: () => import('../../components/settings/property/rules/rules.module').then(m => m.RulesModule)
	},
	{
		path: 'reports',
		loadChildren: () => import('../../components/settings/property/reports/reports.module').then(m => m.ReportsModule)
	},
	{
		path: 'paymenttypes',
		loadChildren: () => import('../../components/settings/property/paymenttypes/paymenttypes.module').then(m => m.PaymentTypesModule)
	},
	{
		path: 'minterm',
		loadChildren: () => import('../../components/settings/property/minterm/minterm.module').then(m => m.MinTermModule)
	},
	{
		path: 'locations',
		loadChildren: () => import('../../components/settings/property/locations/locations.module').then(m => m.LocationsModule)
	},
	{
		path: 'reviewtypes',
		loadChildren: () => import('../../components/settings/property/reviewtypes/reviewtypes.module').then(m => m.ReviewTypesModule)
	},
	{
		path: 'personaltypes',
		loadChildren: () => import('../../components/settings/personal/personaltypes/personaltypes.module').then(m => m.PersonalTypesModule)
	},
	{
		path: 'personalrooms',
		loadChildren: () => import('../../components/settings/personal/personalrooms/personalrooms.module').then(m => m.PersonalRoomsModule)
	},
	{
		path: 'personaltasks',
		loadChildren: () => import('../../components/settings/personal/personaltasks/personaltasks.module').then(m => m.PersonalTasksModule)
	},
	{
		path: 'tenantterm',
		loadChildren: () => import('../../components/settings/tenant/tenantterm/tenantterm.module').then(m => m.TenantTermModule)
	},
	{
		path: 'tenantreviewtype',
		loadChildren: () => import('../../components/settings/tenant/tenantreviewtype/tenantreviewtype.module').then(m => m.TenantReviewTypeModule)
	},
	{
		path: 'tenanttarifs',
		loadChildren: () => import('../../components/settings/tenant/tenanttarifs/tenanttarifs.module').then(m => m.TenantTarifsModule)
	},
	{
		path: 'landlordtarifs',
		loadChildren: () => import('../../components/settings/landlord/landlordtarif/landlordtarif.module').then(m => m.LandlordTarifsModule)
	},
	{
		path: 'landlordpremiums',
		loadChildren: () => import('../../components/settings/landlord/landlordpremium/landlordpremium.module').then(m => m.LandlordPremiumsModule)
	},
	{
		path: 'restauranttarifs',
		loadChildren: () => import('../../components/settings/restaurant/restauranttarifs/restauranttarifs.module').then(m => m.RestaurantTarifsModule)
	},
	{
		path: 'restauranttypes',
		loadChildren: () => import('../../components/settings/restaurant/restauranttypes/restauranttypes.module').then(m => m.RestauranttypesModule)
	},
	{
		path: 'restaurantreviews',
		loadChildren: () => import('../../components/settings/restaurant/restaurantreviews/restaurantreviews.module').then(m => m.RestaurantReviewsModule)
	},
	{
		path: 'feedbacktype',
		loadChildren: () => import('../../components/settings/feedbacktype/feedbacktype.module').then(m => m.FeedbackTypeModule)
	},
	{
		path: 'landlordterm',
		loadChildren: () => import('../../components/settings/landlord/landlordterm/landlordterm.module').then(m => m.LandlordTermModule)
	},
	{
		path: 'restaurantterm',
		loadChildren: () => import('../../components/settings/restaurant/restaurantterm/restaurantterm.module').then(m => m.RestaurantTermModule)
	},
	{
		path: 'admins',
		loadChildren: () => import('../../components/admins/admins.module').then(m => m.AdminsModule)
	},
	{
		path: 'users',
		loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule)
	},
	{
		path: 'user/:id',
		loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule)
	},
	{
		path: 'restaurants',
		loadChildren: () => import('../../components/restaurants/restaurants.module').then(m => m.RestaurantsModule)
	},
	{
		path: 'restaurant/:id',
		loadChildren: () => import('../../components/restaurant/restaurant.module').then(m => m.RestaurantModule)
	},
	{
		path: 'reviews',
		loadChildren: () => import('../../components/reviews/reviews.module').then(m => m.ReviewsModule)
	},
	{
		path: 'properties',
		loadChildren: () => import('../../components/properties/properties.module').then(m => m.PropertiesModule)
	},
	{
		path: 'property/:id',
		loadChildren: () => import('../../components/property/property.module').then(m => m.PropertyModule)
	},
	{
		path: 'transactions',
		loadChildren: () => import('../../components/transactions/transactions.module').then(m => m.TransactionsModule)
	},
	{
		path: 'support',
		loadChildren: () => import('../../components/support/support.module').then(m => m.SupportModule)
	},
	{
		path: 'stat',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'reports',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'configs',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'complaintreview',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'complaintproperty',
		loadChildren: () => import('../../components/test-page/test-page.module').then(m => m.TestPageModule)
	},
	{
		path: 'backup',
		loadChildren: () => import('../../components/backup/backup.module').then(m => m.BackupModule)
	},
	{
		path: 'verify',
		loadChildren: () => import('../../components/verif/verif.module').then(m => m.VerifModule)
	},
	{
		path: 'outcomes',
		loadChildren: () => import('../../components/outcomes/outcomes.module').then(m => m.OutcomesModule)
	},
	{
		path: 'restaurantingredients',
		loadChildren: () => import('../../components/settings/restaurant/restaurantingredients/restaurantingredients.module').then(m => m.RestaurantingredientsModule)
	}
];
